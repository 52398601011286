@use 'styles/base/mixins';
@use 'styles/base/variables';

.mainNavigationMobileToggle {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin-left: auto;
  width: 2.75rem;
  height: 2.75rem;
  margin-right: -0.375rem;

  @include mixins.screen-sm {
    margin-right: -0.5625rem;
  }

  @include mixins.screen-md {
    :global(.isTouch) & {
      margin-left: 0.5rem;
    }
  }

  &Inner {
    width: 1.5rem;
    position: relative;
    margin-top: -0.125rem;

    span {
      position: absolute;
      top: 50%;
      display: block;
      width: 100%;
      height: 0.125rem;
      transition: background-color variables.$transition-fast
        variables.$transition-timing-ease-in-out;
      background: var(--gb-brand-primary);

      &::before,
      &::after {
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--gb-brand-primary);
        content: '';
        transition: transform variables.$transition-fast variables.$transition-timing-ease-in-out;
      }

      &::before {
        transform: translateY(-340%);
      }

      &::after {
        transform: translateY(340%);
      }
    }
  }

  // state
  :global(.mobilenav-open) & {
    z-index: 50;

    span {
      background-color: transparent;

      &::before {
        transform: translateY(0) rotate(45deg);
      }

      &::after {
        transform: translateY(0) rotate(-45deg);
      }
    }
  }
}
