@use 'styles/base/variables';

.back {
  color: var(--gb-brand-primary);
  margin-bottom: 1.25rem;
  font-size: 0.9375rem;

  i {
    font-size: 0.75rem;
    margin-right: 0.5rem;

    &::before {
      margin-bottom: 3px;
    }
  }
}

.selectorheadline {
  text-transform: none;
}

.mobileLanguageSwitch {
  label {
    position: relative;
  }
}

:global(.dropdown).languageSelector {
  height: 2.375rem;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 1.5625rem;

  &:global(.dropdown--active) {
    :global(.dropdown__trigger) {
      color: var(--gb-brand-primary);
    }

    :global(.dropdown__content) {
      display: -ms-flexbox;

      .languageItem {
        display: -ms-flexbox;
      }
    }
  }

  &:global(.campus) {
    margin-right: 0;
  }

  &:global(.campus .dropdown__trigger) {
    background-color: variables.$gb_white;
  }

  :global(.dropdown__trigger) {
    border: none;
    font-weight: variables.$font-weight-medium;
    background-color: variables.$gb_grey_050;
    font-size: 0.75rem;
    padding: 0;

    &:after {
      font-size: 0.5625rem;
      position: relative;
      margin-left: 0.3125rem;
      top: auto;
      right: auto;
    }

    // state
    &:focus,
    &:hover {
      color: var(--gb-brand-primary);
    }
  }

  :global(.dropdown__content) {
    margin-top: 0.375rem;
    position: absolute;
    top: 100%;
    left: -1.25rem;
    box-shadow: none;

    &::before,
    &::after {
      position: absolute;
      left: 1.625rem;
      transform: translateX(-50%);
      content: ' ';
      width: 0;
      height: 0;
      border-style: solid;
    }

    &::before {
      border-width: 0 0.625rem 0.625rem 0.625rem;
      border-color: transparent transparent variables.$gb_white transparent;
      top: -0.55rem;
      z-index: 1;
    }

    &::after {
      border-width: 0 0.75rem 0.675rem 0.75rem;
      border-color: transparent transparent variables.$gb_grey_100 transparent;
      top: -0.675rem;
    }

    ul {
      padding: 0.85rem 0;
      box-shadow: variables.$default-box-shadow;
      width: max-content;
      background-color: variables.$gb_white;

      li {
        position: relative;

        :local(.control) {
          display: block;
          width: 100%;
          font-size: 0.875rem;
          font-weight: normal;
          text-align: left;
          padding: 0.375rem 2.8125rem;
        }

        i {
          position: absolute;
          left: 1.25rem;
          top: 50%;
          transform: translateY(-53%);
          font-size: 0.875rem;
          color: var(--gb-brand-primary);
        }
      }
    }
  }
}

.languageItem {
  padding: 0;
  color: variables.$gb_black;
  font-size: variables.$font-size-small;
  font-weight: variables.$font-weight-regular;

  .control {
    &:hover {
      color: var(--gb-brand-primary);
    }
  }

  &.disabledItem {
    .control,
    .control:hover {
      color: variables.$gb_grey_400;
    }
  }
}

.active {
  font-weight: variables.$font-weight-medium;

  .control {
    color: var(--gb-brand-primary);
  }
}

.control {
  color: variables.$gb_grey_650;
  padding: 0 10px;
}

.disabled {
  cursor: default;
}

.offCanvasLanguage {
  position: absolute;
  top: 0;
  transform: translateX(-100%);
  height: 100%;
  width: 100%;
  z-index: 100;
  padding: 1.875rem;
  opacity: 0;

  &:global(.animation--finished) {
    transform: translateX(0%);
    opacity: 1;
    pointer-events: all;
  }
}

:global(.language--shown) {
  .offCanvasLanguage {
    animation: languageswitch-open 0.4s forwards;
    animation-delay: 0.8s;
  }
}

:global(.language--hidden) {
  .offCanvasLanguage {
    animation: languageswitch-close 0.4s forwards;
  }
}

// Animations
// -----------------------------
@keyframes languageswitch-open {
  0% {
    transform: translateX(-0.875rem);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
    pointer-events: all;
  }
}
@keyframes languageswitch-close {
  0% {
    transform: translateX(0%);
    opacity: 1;
  }
  100% {
    transform: translateX(-0.875rem);
    opacity: 0;
    pointer-events: none;
  }
}
