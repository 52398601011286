@use 'styles/base/mixins';
@use 'styles/base/variables';

.languageToggleWrapper {
  border-top: 1px solid variables.$gb_grey_150;
  margin-left: -0.875rem;
  margin-right: -0.875rem;

  .languageToggleInner {
    display: flex;
    padding: 0.5rem 0.875rem 0 0.875rem;

    .languageToggleDesc,
    button {
      padding: 0.625rem 0;
      line-height: 1;
    }

    .languageToggleDesc {
      flex-shrink: 0;
      color: variables.$gb_black;
      font-size: 0.875rem;
      margin-right: 0.25rem;
    }

    button {
      position: relative;
      width: 100%;
      text-align: left;
      font-size: 0.875rem;
      font-weight: variables.$font-weight-medium;
      padding-right: 2rem;

      &::after {
        @include mixins.web20-icon('chevron-large-right');
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        color: variables.$gb_black;
        font-size: 0.75rem;
      }
    }
  }
}
