.fakeShadow {
  box-shadow: 0 0.1875rem 0.75rem rgba(0, 0, 0, 0.2);
  width: 100vw;
  position: absolute;
  top: -1rem;
  height: 1rem;
  left: 0;
  right: 0;
  z-index: 300;
  pointer-events: none;
  opacity: 1;
}
